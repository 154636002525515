import React from "react";
import Header from "./Header/Header";

// Create the default/base application layout.
export default function Layout(props) {
    return (
        <>
        <Header pageTitle='Home' />
            <main>
                {props.children}
            </main>
        </>
    )
}