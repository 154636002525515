import { React, useEffect, useState } from 'react'

import Layout from '../components/Layout'
import Map from '../components/Map/Map'
import Sidebar from '../components/Sidebar/Sidebar'

export default function Home () {

    const [listings, setListings] = useState([])
    
    useEffect(() => {
        const getListings = async () => {
            const res = await fetch("https://api.garagesales.acmcloud.io/GarageSales")
            const data = await res.json()

            setListings(data)
        }

        getListings();
    }, [])


    return (
        <Layout pageTitle='Home'>
            <Sidebar listings={listings} />
            <Map listings={listings} />
        </Layout>
    )
}