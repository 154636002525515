import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import MapMarker from './MapMarker';
import DetailsModal from '../DetailsModal/DetailsModal';

const DEFAULT_LAT = -25;
const DEFAULT_LNG = 131;
const DEFAULT_ZOOM = 5;

export default function Map(props) {
    const { lat, lng, zoom } = parseQueryParams();

    const location = {
        center: { lat: parseFloat(lat), lng: parseFloat(lng) },
        zoom: parseInt(zoom)
    };

    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
    const [listingDetails, setListingDetails] = useState(null)

    const handleMapMarkerClick = (listing) => {
        setListingDetails({
            address: listing.address,
            date: listing.date,
            startTime: listing.startTime,
            finishTime: listing.finishTime,
            description: listing.description,
            orderId: listing.orderId, 
        })

        setIsDetailsModalOpen(!isDetailsModalOpen)
        console.log(listing.orderId);
    }

    const mapMarkers = props.listings.map(listing => {
        return <MapMarker
                    key={listing.orderId}
                    lat={listing.latitude}
                    lng={listing.longitude}
                    onClick={() => handleMapMarkerClick(listing)}
                />
            }
        )

    return (
        <div className='map'>
            <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GARAGESALES_MAPS_API_KEY }}
                center={location.center}
                zoom={location.zoom}
                options={{ fullscreenControl: false }}
            >
                {mapMarkers}
            </GoogleMapReact>
            {isDetailsModalOpen && <DetailsModal listing={listingDetails} />}
        </div>
    );
}

function parseQueryParams() {
    const queryParams = new URLSearchParams(window.location.search);
    const lat = queryParams.get('lat') || DEFAULT_LAT;
    const lng = queryParams.get('lng') || DEFAULT_LNG;
    const zoom = queryParams.has('lat') && queryParams.has('lng') ? 12 : DEFAULT_ZOOM;
    return { lat, lng, zoom };
}